<template>
    <div class="scroll-container">
      <div class="scroll-content">
      <div class="form-group">
        <div class="row">
          <div class="col-4">
            <label for="iStamp">Select Year</label>
            <!-- <input type="year" @input="genReport()" name="idate" id="idate" class="form-control" v-model="shroffObj.idate" /> -->
            <select v-model="shroffObj.idate" @change="genReport()" name="idate" id="idate" class="form-control">
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
        </div>
        </div>
      </div>
    
      <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    
      <div class="invoice-contain">
        <div class="invoice-form-contain">
          <form id="invoiceForm" novalidate autocomplete="off">
            <div id="PrintContent"><div class="hidden-content">This is hidden content.</div>
              <!-- <h5>ප්‍රා.ස.5/නා.ස.5</h5> -->
              <div class="form-group">
                <div class="heading">
                  <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">
                    Reccurant Revenue Summary |පුනාරාවර්ථන ආදායම් සාරාංශය(වැඩසටහන් අනුව)<br>
                    {{ sabhadetail.sb_name_en }} | {{ sabhadetail.sb_name_sin }}<br>
                    {{ sabhadetail.sb_address }}
                  </h4>
                </div>
                <h6 style="padding-top: 0px; padding-bottom: 0px;">
                  <label for="iDate">
                    Year | වර්ෂය : {{ this.shroffObj.idate }}
                  </label>
                </h6>
                <table style="width: 98%; text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">
                 
                  <thead>
                    <tr >
                      <!-- <th  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;"></th> -->
                      <th  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;"> ආදායම් විස්තරය<br>Revenue Description</th>
                        <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;width: 7%; white-space: normal;" v-for="head in headsar" :key="head.prog_id">{{ head.program_sin}}<br>{{ head.program_en}}</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">එකතුව<br>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in allProghead" :key="index">
                <tr >
                    <td style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;width: 25%;"><b>{{ item.p_head_des_sin }}</b></td>
                    
                   <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40; width: 7%; white-space: normal;" v-for="head in headsar" :key="head.prog_id">
    <!-- {{incomeMap[r.p_head_id] && incomeMap[r.p_head_id][head.prog_id] && incomeMap[r.p_head_id][head.prog_id].amount || 0 }}  -->
    {{ $formatNumber(getIncomeAmount(item.p_head_id,head.prog_id))}}
  </td> 
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"> {{  $formatNumber(getSbRateHeadTotal(item.p_head_id).toFixed(2))}}</td>
                      
          </tr>
    
                  <!-- </template> -->
                </template>
                    <tr>
                      <th  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total:</th>
                      
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="h in headsar" :key="h.prog_id">
                        <div><b>{{ $formatNumber(Number(calculateTotal(h.prog_id)).toFixed(2)) }}</b></div>
                      </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;width: 13%;"><b>{{  $formatNumber(head_total.toFixed(2)) }}</b></td>
                      </tr>
             
                  </tbody>
                </table>
                <br>
                
                <h6>Prepared By | සකස් කලේ ................................................................................... &nbsp; &nbsp;&nbsp;Checked By |පරික්ෂා කලේ .......................................................................... </h6>
                <h6>Date | දිනය&nbsp;&nbsp;................................................................................&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Date | දිනය &nbsp;&nbsp;................................................................................</h6>
              </div>
            </div>
          </form>
          <div class="form-group">
            <input type="button" value="Print" class="btn" @click="fetchDataAndPrint" />
            <input  type="button" id="excebtn" value="To Excel" class="btn"  @click="exportTableToExcel" :disabled="disableButton" />
            
            
          </div>
        </div>
        <br>
      </div>
      </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import VueBasicAlert from 'vue-basic-alert';
    import * as XLSX from 'xlsx';
    // import * as XLSXStyle from 'xlsx-style';
    export default {
      name: "LgFiveAnnually",
      data() {
        return {
          nic: "",
          sabha: "",
          userLevel: "",
          userName: "",
          sabhadetail: [],
         
         
          date: new Date(),
        
          headsar: [],
          head_total: 0,
          shroffObj: { idate:"" },
          
          incomeMap: {},
          vat_total:0,
          incomeHsabha: [] ,// Array to store dates
          selectedMonth:"",
          selectedYear:"",
          discount:0,
          totalGenData: [],
          disableButton:true,
          headlen:0,
          totalResults: {},
          allProghead:[],
            allHeads: [],
         
         
        };
     },
    
      created() {
        this.getDataFromSessionStorage();
    //    this.genReport()
      },
      computed: {
    years() {
      // Generate an array of years, e.g., from 2000 to current year + 5
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = 2023; year <= currentYear + 3; year++) {
        years.push(year);
      }
      return years;
    }
  },
    
      methods: {
   
    formatDate(date) {
        const day = date.getDate().toString().padStart(2, "0");
        const monthcc = (date.getMonth()).toString();
        let month=""
        if(monthcc==0){
             month = 12
        }else{
             month = (date.getMonth()).toString().padStart(2, "0");
        }
          const year = date.getFullYear().toString();
          return `${year}-${month}-${day}`;
    },
  
    
        async getDataFromSessionStorage() {
          const data = JSON.parse(sessionStorage.getItem('userData'));
          if (data) {
            this.nic = data.nic;
            this.sabha = data.sabha;
            this.userLevel = data.userLevel;
            this.userName = data.userName;
          }
          this.sabhadetail = (await axios.get('/pra_sabha/' + this.sabha)).data;
     
        },
   
   
        async genReport() {
      let sabhacode = this.sabha;
    //   const selectedmon =  (this.shroffObj.idate).split("-")
    //   this.selectedMonth=selectedmon[1]
      this.selectedYear=this.shroffObj.idate
      this.head_total=0
      this.allHeads=[]
      // this.getDatesInMonth(this.selectedMonth, this.selectedYear);
      // await this.fetchTotalGenData();
      
      try {
        const headsOfProg = await axios.get(`/allproghead/`);
            this.allProghead = headsOfProg.data;
  
            for (let progh of this.allProghead) {
            let  progheadId= progh.p_head_id
            let  progheadName= progh.p_head_des_sin
            const headsbyprog = await axios.get(`/sbheadsbyproghead/${sabhacode}/${progheadId}`);
            let sbhead = headsbyprog.data;
          //   this.allHeads.push(progheadId,progheadName,sbhead)
          this.allHeads.push({ progheadId, progheadName, sbhead });
            }
        
        const headsFoSabha = await axios.get(`/sbprog/${sabhacode}`);
          this.headsar = headsFoSabha.data;
          this.headlen=parseInt(this.headsar.length);
  
          const incomeFoSabha = await axios.get(`/sbvotes/${sabhacode}`);
          this.incomeHsabha = incomeFoSabha.data;
  
          this.incomeMap = {};
  
          for (let sum of this.incomeHsabha) {
            try {
                // Make an asynchronous request to fetch income summary data
                const allinvoiceIncomeheads = await axios.get('/incomesummary/' + this.sabha + '/' + sum.prog_id + '/' + sum.p_head_id + '/' + this.selectedYear);

                // Initialize incomeMap[sum.p_head_id] if it doesn't exist
                if (!this.incomeMap[sum.p_head_id]) {
                    this.incomeMap[sum.p_head_id] = {};
                }

                // Extract amount from the response data, defaulting to 0 if undefined
                const amount = parseFloat(allinvoiceIncomeheads.data[0]?.amount) || 0;

                // Assign amount to incomeMap and accumulate to head_total
                this.incomeMap[sum.p_head_id][sum.prog_id] = amount;
                this.head_total += amount;
            } catch (error) {
                console.error('Error fetching income summary:', error);
                // Handle errors as needed, e.g., set default values or show error messages
                this.incomeMap[sum.p_head_id][sum.prog_id] = 0; // Default value in case of error
            }
        }
              this.disableButton = false;
      } catch (error) {
          console.error('Error generating report:', error);
      }
      
  },
  getIncomeAmount(headName,prog) {
    
      try {
      const value = this.incomeMap[headName] && this.incomeMap[headName][prog] !== undefined
        ? this.incomeMap[headName][prog]
        : 0;
  
      return typeof value === 'number' && value !== 0 ? value.toFixed(2) : '';
    } catch (error) {
      console.error('Error in getIncomeAmount:', error);
      return '';
    }
    },
    calculateTotal(prog) {
    let total = 0;

    // Iterate through each head in incomeMap
    for (let p_head_id in this.incomeMap) {
      if (Object.prototype.hasOwnProperty.call(this.incomeMap, p_head_id)) {
    if (this.incomeMap[p_head_id][prog]) {
        total += parseFloat(this.incomeMap[p_head_id][prog]);
    }
}
    }

    // Return the formatted total
    return total.toFixed(2);
},

        getSbRateHeadTotal(p_head_id) {
      const headAmounts = this.incomeMap[p_head_id] || {};
      let total = 0;
      for (let key in headAmounts) {
        // Use Object.prototype.hasOwnProperty.call to avoid the ESLint warning
        if (Object.prototype.hasOwnProperty.call(headAmounts, key)) {
          total += headAmounts[key];
        }
      }
      return total;
    },
    fetchDataAndPrint() {
  
  const prtHtml = document.getElementById('PrintContent').innerHTML;
  
  // Open the print window
  // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,status=0');
  const WinPrint = window.open('', '', '');
  WinPrint.document.write(`
  ${prtHtml}
  `);
  
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
  },
  exportTableToExcel() {
      // Get the HTML table element
      const table = document.getElementById('PrintContent');
      
      // Convert the table to a worksheet
      const ws = XLSX.utils.table_to_sheet(table);
  
      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // Generate an Excel file and trigger download
      XLSX.writeFile(wb, 'ExportedContent.xlsx');
    }
  },
      components: {
        VueBasicAlert
      }
    };
    </script>
   <style scoped>
   .hidden-content {
    display: none; /* Initially hidden */
  }
   .invoice-contain {
       background-color: #fff;
       /* height: 100vh; */
       /* padding: 2rem 25%; */
       padding: 1rem;
       font-size: 16px;
       align-content: center;
       min-height: 72.3vh;
   }
   .invoice-contain .invoice-form-contain {
       background: #fff;
   
   }
   
   .invoice-contain .invoice-form-contain form {
       position: relative;
   }
   .invoice-contain .invoice-form-contain form h3 {
       /* padding-bottom: 1rem; */
       font-size: 1.7rem;
       text-transform: uppercase;
       color: #130f40;
       margin: 0;
   }
   .ttable {
       
       width: 100%;
       border-width:1px;
       border-color : #130f40;
       /* border-style: solid; */
       font-size: 0.9rem;
       /* background-color :white; */
       /* padding-left: 3rem; */
        /* height: 3rem; */
        border-collapse: collapse;
   }
   th  {
       text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  
   
   .table-responsive {
       margin-top: 8vh;
       height: 500px;
   }
   
   .action-btn,
   .cancel-btn,
   .paid-btn {
       width: 100px;
       height: 25px;
       color: white;
       text-transform: capitalize;
   }
   
   .action-btn {
       background-color: #0da9ef;
       margin-right: 10px;
   }
   
   .cancel-btn,
   
   .action-btn:hover {
       background-color: #27ae60;
   }
   
    .invoice-contain .invoice-form-contain form .form-group {
       margin:0;
       font-size: 1rem;
   } 
   
   .invoice-contain .invoice-form-contain form .form-group .error-mess {
       font-size: 1.5rem;
       position: relative;
       color: rgb(243, 47, 47);
       margin: 0;
       padding: 0;
   }
   .invoice-contain .btn {
       background: rgb(223, 80, 14);;
       color: rgb(240, 234, 231);
       border: 1rem;
       padding: 1rem;
       font: inherit;
       cursor: pointer;
       outline: 1rem;
       text-align: center;
      
   }
   .invoice-contain .btnrow{
      align-content: center;
      padding-left: 25rem;
   }
  
   .scroll-container {
    width: 100%; /* Set the width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent content from wrapping */
  }
  /*
  //.scroll-content {
     Optional: Set width if necessary 
  //}*/
   </style>